import moment from 'moment'
import { ROLE_CP, ROLE_CS, ROLE_MERCH } from 'Common/constants/global'

class DossierUnicoUtils {
  static translateStatus(status) {
    switch (status) {
      case 'ACTIVE':
        return 'Attivo'
      case 'INACTIVE':
        return 'Inattivo'
      case 'DRAFT':
        return 'Bozza'
      case 'DELETED':
        return 'Eliminato'
      default:
        return status
    }
  }

  static canDeleteRecord(record) {
    return ['DRAFT', 'ARCHIVE'].includes(record.stato)
  }

  static translateState(state) {
    switch (state) {
      case 'DRAFT':
        return 'Creato'
      case 'CLOSED_HQ':
        return 'Confermato Sede'
      case 'CLOSED_STORE':
        return 'Confermato Negozio'
      case 'INSTALLED':
        return 'Impiantato'
      case 'ARCHIVED':
        return 'Archiviato'
      case 'MOVED':
        return 'Trasferito'
      case 'DELETED':
        return 'Eliminato'
      default:
        return state
    }
  }

  static getColorByState(state) {
    switch (state) {
      case 'DRAFT':
        return '#9e9e9e'
      case 'CLOSED_HQ':
      case 'CLOSED_STORE':
        return '#33bbcc'
      case 'INSTALLED':
        return '#66cc33'
      case 'ARCHIVED':
      case 'MOVED':
        return '#d3d3d3'
      case 'DELETED':
        return '#cc3333'
      default:
        return undefined
    }
  }

  static getChanges(field, value, { user, data }) {
    const changes = {}

    switch (field) {
      case 'impiantoDa':
        const impiantoDa = moment(value)

        if (!impiantoDa.isValid()) break

        changes.impiantoEffettivo = value
        changes.completamentoNegozioA = impiantoDa
          .clone()
          .subtract(37, 'days')
          .toISOString()
        changes.completamentoNegozioDa = impiantoDa
          .clone()
          .subtract(48, 'days')
          .toISOString()

        break

      case 'cluster':

        switch (value?.[user?.store]) {
          case 'a':
            changes.facingStore = data?.facingA;
            break;

          case 'ab':
            changes.facingStore = data?.facingAb;
            break;

          case 'abc':
            changes.facingStore = data?.facingAbc;
            break;

          default:
        }

        break;

      default:
    }

    return changes
  }

  static canEdit(field, user, data) {
    if (data?.removed) {
      return false;
    }

    if (!['impiantoDa', 'impiantoEffettivo'].includes(field) && (!data?.impiantoEffettivo || moment(data?.impiantoEffettivo).isSameOrBefore(moment()))) {
      return false
    }

    switch (field) {
      case 'impiantoDa':
      case 'impiantoA':
      case 'completamentoNegozioDa':
      case 'completamentoNegozioA':
        return user?.role === ROLE_CP;

      case 'impiantoEffettivo':
        return (
          (user?.role === ROLE_CP &&
            ['DRAFT', 'ARCHIVED', 'INSTALLED'].includes(data.stato)) ||
          (user?.role === ROLE_CS && data.stato === 'CLOSED_HQ')
        )

      case 'gamma':
      case 'precotopReappro':
      case 'prevVendutoAnnuo':
      case 'idRefSostituita':
      case 'tipoSostituzione':
      case 'noteCp':
        return (
          user?.role === ROLE_CP &&
          ['DRAFT', 'ARCHIVED', 'INSTALLED'].includes(data.stato)
        )

      case 'topReappro':
        return (
          user?.role === ROLE_CS &&
          data.stato === 'CLOSED_HQ' &&
          data.gamma !== 'D' &&
          (data.gamma !== 'A' || (data.gamma === 'A' && data.topMdh !== true))
        )

      case 'cluster':
        return user?.role === ROLE_CS && data.stato === 'CLOSED_HQ'

      case 'facingA':
      case 'facingAb':
      case 'facingAbc':
        return (
          user?.role === ROLE_MERCH &&
          data.precotopReappro === 1 &&
          data.gamma !== 'S' &&
          ['DRAFT', 'ARCHIVED', 'INSTALLED'].includes(data.stato)
        )

      case 'facingStore':
        return (
          user?.role === ROLE_CS &&
          data.stato === 'CLOSED_HQ' &&
          data.gamma !== 'S' &&
          data?.cluster?.[user?.store] &&
          DossierUnicoUtils.computeStoreValue(data, 'topReappro', user?.store, data.precotopReappro) === 1
        )

      case 'numPdd':
      case 'stock':
      case 'noteMerch':
        return (
          user?.role === ROLE_MERCH &&
          ['DRAFT', 'ARCHIVED', 'INSTALLED'].includes(data.stato)
        )

      case 'noteCs':
        return user?.role === ROLE_CS && data.stato === 'CLOSED_HQ'

      default:
        return false
    }
  }

  static getFieldWarnings = (field, record, metadata) => {
    const { user } = metadata
    const messages = []

    switch (field) {
      case 'facingA':
      case 'facingAb':
      case 'facingAbc':
        const expectedGamma =
          field === 'facingA' ? 'A' : field === 'facingAb' ? 'B' : 'C'

        if (record[field] > 100) {
          messages.push('Il valore deve essere inferiore a 100')
        } else if (!record[field]) {
          messages.push('Valore obbligatorio')
        } else if (
          user?.role === 'MERCH' &&
          record.precotopReapproToBe === 1 &&
          record.gamma === expectedGamma &&
          !record[field]
        ) {
          messages.push('Il valore deve essere maggiore di 0')
        }

        break

      default:
    }

    return messages
  }

  static getRecordWarnings = (record, metadata) => {
    const warnings = []

    const fields = Object.keys(record)

    for (const field of fields) {
      warnings.push(
        ...DossierUnicoUtils.getFieldWarnings(field, record, metadata).map(
          o => ({
            message: o,
            field,
          }),
        ),
      )
    }

    return warnings
  }

  static getFieldErrors = (field, record, metadata) => {
    const { user } = metadata
    const messages = []

    switch (field) {
      case 'impiantoA':
      case 'impiantoDa':
        if (!record[field]) {
          messages.push('Data impianto obbligatoria')
        }
        break

      case 'numPdd':
        if (!record[field] && record.precotopReappro === 1) {
          messages.push('Num PDD obbligatorio con precotop reappro 1')
        }

      default:
    }

    return messages
  }

  static getRecordErrors = (record, metadata) => {
    const errors = []

    const fields = Object.keys(record)

    for (const field of fields) {
      errors.push(
        ...DossierUnicoUtils.getFieldErrors(field, record, metadata).map(o => ({
          message: o,
          field,
        })),
      )
    }

    return errors
  }

  static getRecordIssues = (record, metadata) => {
    const issues = []
    issues.push(
      ...DossierUnicoUtils.getRecordWarnings(record, metadata).map(o => ({
        ...o,
        type: 'warning',
      })),
    )
    issues.push(
      ...DossierUnicoUtils.getRecordErrors(record, metadata).map(o => ({
        ...o,
        type: 'error',
      })),
    )
    return issues
  }

  static getLabelForSelectedCluster(cluster) {
    if (!cluster) return ''

    const translation = {
      'a': 'S',
      'ab': 'M',
      'abc': 'L'
    }

    return translation[cluster]
  }

  static computeStoreValue(record, field, storeId, defaultValue = null) {
    return record?.[field]?.[storeId] ?? defaultValue
  }
}

export default DossierUnicoUtils
